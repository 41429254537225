<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-21 15:52:16
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-13 16:31:23
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/ConfigDetail/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="adplace-config-container">
    <GoBack @goBack="goBack" title1="预算管理" title2="广告源" title3="配置详情" />
    <ConfigLayout :title1="type === '1' ? '供应商列表' : '广告位列表'" title2="配置列表">
      <Head slot="head" v-model="type" @change="initBindPublisherAndPlace"/>
      <PlaceLeftMain v-if="type === '2'" @addSource="addSource" slot="left" :checkedIdList="checkedIdList" />
      <PublisherLeftMain v-if="type === '1'" @addSource="addSource" slot="left" :checkedIdList="checkedIdList" />
      <div slot="right" class="main">
        <div class="main-head">
          <el-popover :disabled="selectIdList.length === 0" placement="bottom-start" :ref="'popover'">
            <div style="padding: 5px">
              <a-radio style="font-weight: bold; color: #333" :checked="true">状态</a-radio>
              <div style="display: flex; margin-top: 10px">
                <el-tooltip effect="dark" :disabled="selectIdList.length > 0" content="" placement="right-start">
                  <el-button
                    plain
                    class="btnHover"
                    @click="handleBatch(handleBatchConfig)"
                  >利润配置</el-button
                  >
                </el-tooltip>
                <el-tooltip effect="dark" :disabled="selectIdList.length > 0" content="" placement="right-start">
                  <el-button
                    plain
                    class="btnHover"
                    @click="handleBatch(handleBatchDelete)"
                  >删除</el-button
                  >
                </el-tooltip>
                <el-tooltip effect="dark" :disabled="selectIdList.length > 0" content="" placement="right-start">
                  <el-button
                    plain
                    class="btnHover"
                    @click="handleBatch(handleChangeSts, selectIdList, 'A')"
                  >开启</el-button
                  >
                </el-tooltip>
                <el-tooltip effect="dark" :disabled="selectIdList.length > 0" content="" placement="right-start">
                  <el-button
                    plain
                    class="btnHover"
                    @click="handleBatch(handleChangeSts, selectIdList, 'S')"
                  >关闭</el-button
                  >
                </el-tooltip>
              </div>
            </div>
            <m-link-button
              slot="reference"
              :disabled="selectIdList.length === 0"
              class="btn"
              iconPos="left"
            >批量操作<BatchSvg
              slot="icon"
            /></m-link-button>
          </el-popover>
        </div>
        <a-spin :spinning="isLoading">
          <el-table
            :data="dataList"
            :row-key="(row) => row.id"
            style="border-bottom: none"
            @select-all="selectionChange"
            @selection-change="selectionChange"
          >
            <el-table-column align="left" type="selection" min-width="48"> </el-table-column>
            <el-table-column prop="name" label="供应商名称/ID" min-width="180">
              <div slot-scope="{ row }" class="name-row" style="width: 160px">
                <div class="name">{{ row.publisherName }}</div>
                <div class="id">{{ row.publisherId }}<CopySvg class="copy-icon" @click="copyId(row.publisherId)" /></div>
              </div>
            </el-table-column>
            <el-table-column v-if="type === '2'" prop="name" label="广告位名称/ID" min-width="180">
              <div slot-scope="{ row }" class="name-row" style="width: 160px">
                <div class="name">{{ row.placeName }}</div>
                <div class="id">{{ row.placeId }}<CopySvg class="copy-icon" @click="copyId(row.placeId)" /></div>
              </div>
            </el-table-column>
            <el-table-column v-if="type === '1'" prop="placeSts" label="自定义广告位" min-width="140">
              <template slot-scope="{ row }">
                <m-sts :sts="row.placeSts === 'A'"/>
              </template>
            </el-table-column>
            <el-table-column v-if="type === '1'" prop="placeNum" label="广告位个数" min-width="120">
              <template slot-scope="{ row }">
                <span>{{ row.placeNum || 0 }}个</span>
              </template>
            </el-table-column>
            <el-table-column v-if="type === '1'" prop="profitRate" label="利润率" min-width="90">
              <template slot-scope="{ row }">
                <span>{{ row.profitRate || 0 }}%</span>
              </template>
            </el-table-column>
            <el-table-column v-if="type === '2'" prop="positionName" label="广告样式" min-width="120"></el-table-column>
            <el-table-column prop="sts" label="状态">
              <template slot-scope="{ row }">
                <a-switch
                  size="small"
                  :checked="row.sts === 'A'"
                  @click="handleChangeSts([row.id], row.sts === 'A' ? 'S' : 'A')"
                ></a-switch>
              </template>
            </el-table-column>
            <!-- <el-table-column v-if="+sourceType && role !== 'SUPER_ADMIN' && roleId !== 4" prop="strategySts" label="利润策略" min-width="122">
              <template slot-scope="{ row }">
                  <span v-if="row.strategySts === 'A'">已配置</span>
                  <span v-else style="color:red">未配置</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="strategySts" label="利润策略" min-width="116">
              <template slot-scope="{ row }">
                <template v-if="+sourceType || +row.sourceType">
                  <span v-if="row.strategySts === 'A'">已配置</span>
                  <span v-else style="color:red">未配置</span>
                </template>
                <template v-else>
                  {{ row.strategySts === 'A' ? '已配置' : '继承上级' }}
                </template>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="199">
              <template slot-scope="{ row }">
                <span class="link-btn" @click="handleConfig(row)">利润配置</span>
                <span class="link-btn" @click="handleClickDeleteBtn(row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </a-spin>
      </div>
    </ConfigLayout>
    <!-- 删除弹窗 -->
    <m-delete-modal
      :delVisible="delVisible"
      title="已配置广告源"
      @modalCancel="delVisible = false"
      @handleDelete="handleDeleteItem"
    />
    <m-delete-modal
      :hasCancle="false"
      :isDelete="false"
      :delVisible="hintVisible"
      title="温馨提示"
      @modalCancel="hintVisible = false"
      @handleDelete="() => hintVisible = false"
    >
      <template>
        当前有{{ sum }}条广告源<span style="color:red">未配置</span>利润策略不能产生利润
      </template>
    </m-delete-modal>
    <EditProfitModel
      :sourceType="sourceType"
      :visible="editProfitVisible"
      :isBatch="isBatch"
      :sourceInfo="sourceInfo"
      @modalCancel="editProfitVisible = false"
      :configType="type"
      :batchIdList="selectIdList"
      from="budget"
      @editSuccess="
        () => {
          editProfitVisible = false
          initBindPublisherAndPlace()
        }
      "
    />
  </div>
</template>

<script>
import GoBack from '@/components/common/GoBack'
import ConfigLayout from '@/layouts/ConfigLayout'
import BatchSvg from '@/assets/icons/batch.svg?inline'
import Head from './Header'
import PlaceLeftMain from './PlaceLeftMain'
import PublisherLeftMain from './PublisherLeftMain'
import { updatePublisherDspSts } from '@/apiForManage/flow/config'
import { bindPublisherAndPlace, getBindDspLink } from '@/apiForManage/budget/config'
import CopySvg from '@/assets/icons/copy.svg?inline'
import { copy } from '@/utils/string'
import EditProfitModel from '../../Flow/components/EditProfitModel'
import { mapState } from 'vuex'
export default {
  components: { GoBack, ConfigLayout, Head, PlaceLeftMain, PublisherLeftMain, CopySvg, EditProfitModel, BatchSvg },
  data () {
    return {
      isLoading: false,
      // 1 供应商 2 广告位
      type: undefined,
      // 返回的路由名
      supplierId: undefined,
      placeId: undefined,
      dataList: [],
      // 服务端已配置的广告源
      checkedIdList: [],
      // 列表选中广告源
      selectIdList: [],
      delVisible: false,
      // 需要删除的广告源id（单个删除使用）
      deleteIdList: [],
      editProfitVisible: false,
      // 需要编辑的广告源信息
      sourceInfo: {},
      // 是否批量编辑利润
      isBatch: false,
      sourceType: 0,
      sum: 0,
      hintVisible: false
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      roleId: (state) => state.user.roleId,
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    })
  },
  async created () {
    this.getRouteQuery()
    await this.initBindPublisherAndPlace()
    this.handleSourceType()
  },
  methods: {
    handleSourceType () {
      if (this.roleId === 4 || this.role === 'SUPER_ADMIN') {
        return
      }
      if (this.sum) {
        // 平台供应商 且 有未开启利润配置的广告源
        this.hintVisible = true
      }
    },
    handleBatch (callback, ...args) {
      callback.apply(this, args)
      this.$refs.popover.showPopper = false
    },
    handleBatchConfig () {
      this.isBatch = true
      this.editProfitVisible = true
    },
    handleDeleteItem () {
      this.handleChangeSts(this.deleteIdList, 'D')
      this.delVisible = false
    },
    handleBatchDelete () {
      this.deleteIdList = this.selectIdList
      this.delVisible = true
    },
    handleClickDeleteBtn (row) {
      this.deleteIdList = [row.id]
      this.delVisible = true
    },
    handleConfig (row) {
      this.editProfitVisible = true
      this.sourceInfo = JSON.parse(JSON.stringify(row))
      this.isBatch = false
    },
    /**
     * @description: 已配置广告源状态修改 删除 开启 关闭
     * @param {*} ids 广告源id
     * @param {*} sts 广告源状态 D A S
     */
    handleChangeSts (ids, sts) {
      updatePublisherDspSts({
        type: this.type,
        ids: ids,
        sts
      }).then((res) => {
        const { code } = res
        if (code === 200) {
          this.$message.success(sts === 'D' ? '删除成功' : '修改成功')
          this.initBindPublisherAndPlace()
        }
      })
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    selectionChange (selection) {
      this.selectIdList = selection.map((item) => item.id)
    },
    async addSource (ids) {
      const query = {
        type: this.type,
        dspId: this.dspId,
        publishIdList: [],
        placeIdList: []
      }
      if (this.type === '1') {
        query.publishIdList = ids
      } else if (this.type === '2') {
        query.placeIdList = ids
      }
      const { code } = await bindPublisherAndPlace(query)
      if (code === 200) {
        this.$message.success('绑定成功')
        this.initBindPublisherAndPlace()
      }
    },
    async initBindPublisherAndPlace () {
      this.isLoading = true
      const { data = {} } = await getBindDspLink({
        type: this.type,
        dspId: this.dspId
      })
      const { list = [], sourceType = 0, sum = 0 } = data
      if (this.type === '2') {
        this.checkedIdList = list.map((item) => item.placeId)
      } else {
        this.checkedIdList = list.map((item) => item.publisherId)
      }
      this.dataList = list
      this.sourceType = sourceType
      this.sum = sum
      this.isLoading = false
    },
    getRouteQuery () {
      const { id } = this.$route.query
      // 1 供应商 2 广告位
      this.type = '1'
      this.dspId = id
    },
    goBack () {
      this.$router.push({
        name: 'budgetsource',
        params: {
          ...this.$route.params
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.btnHover {
  border: none;
  box-shadow: none;
  &:hover {
    color: @primary-color;
    font-weight: 600;
  }
}
// 底部高度
@footHeight: 82px;
@name: adplace;
.@{name}-config-container {
  min-width: calc(1280px - 2 * @mediumMargin);
  margin: @mediumMargin;
  display: flex;
  justify-content: center;
  margin: calc(@mediumMargin + 62px) @mediumMargin @mediumMargin @mediumMargin;
  .main {
    display: flex;
    flex-direction: column;
    .main-head {
      display: flex;
      justify-content: space-between;
      padding-bottom: 11px;
      border-bottom: 1px solid @compBorderColor;
      .right{
        display: flex;
        justify-content: flex-start;
        .search-item{
          width: 150px;
          margin-right: @compMargin;
          &:last-child{
            margin: 0;
          }
        }
      }
    }
    .name-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      .id,
      .name {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .id {
        color: #858fa1;
        font-size: 12px;
        line-height: 17px;
      }
      .copy-icon {
        cursor: pointer;
        min-width: 13px;
        min-height: 13px;
        margin-left: 6px;
      }
    }
    .link-btn {
      color: @primary-color;
      margin-right: 12px;
      cursor: pointer;
    }
  }
}
::v-deep.@{name}-config-container{
  .el-table::before{
    background-color: #fff;
  }
}
</style>
